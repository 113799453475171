<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
  >
    <v-card>
      <v-card-title>
        Recuperar clave
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="pt-2 pb-4">
          Ingrese su email para recibir un enlace de recuperación
        </div>
        <v-text-field
          v-model.trim="email"
          label="Email"
          prepend-inner-icon="far fa-envelope"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          @click="dialog = false"
        >
          Cancelar
          <v-icon>fas fa-send</v-icon>
        </v-btn>
        <v-btn
          color="success"
          @click="enviar()"
        >
          Enviar
          <v-icon right>fas fa-paper-plane</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      email: null
    }
  },
  props: {
    value: Boolean,
    p_email: String
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    dialog (val) {
      if (val) {
        this.email = JSON.parse(JSON.stringify(this.p_email))
      }
    }
  },
  methods: {
    async enviar () {
      if (!this.email) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Ingrese un email',
          color: 'error'
        })
      }
      this.$store.state.loading = true
      await this.$store.dispatch('usuarios/recuperar_clave', this.email)
        .then(() => {
          this.dialog = false
          this.$swal.fire({
            icon: 'success',
            title: 'Email enviado correctamente',
            text: 'Revise su bandeja de entrada'
          })
        })
        .catch((error) => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    }
  }
}
</script>